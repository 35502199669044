<template>
    <div class="form-content">
        <div v-if="$route.name == 'addtourguide' && can('create', 'tour guide') || $route.name == 'updatetourguide' && can('update', 'tour guide')">
            <TourGuideForm />
        </div>

        <div v-else-if="$route.name == 'addemployee' && can('create', 'employee') || $route.name == 'updateemployee' && can('update', 'employee') || $route.name == 'updateprofile'">
            <EmployeeForm :UpdateProfile="false" /> 
        </div>

        <div v-else-if="$route.name == 'updatehotelcontract'">
            <HotelContractForm />
        </div>
        
        <div v-else-if="$route.name == 'updaterestaurantcontract'">
            <RestaurantContractForm />
        </div>

        <div v-else-if="$route.name == 'updatetransportationcontract'">
            <TransportationContractForm />
        </div>

        <div v-else-if="$route.name == 'additinerary' || $route.name == 'updateitinerary'">
            <ItineraryDetail />
        </div>

        <div v-else-if="$route.name == 'newbooking' && can('create', 'booking') || $route.name == 'updatebooking' && can('update', 'booking')">
            <BookingForm />
        </div>        

        <div v-else>
            <NotFoundPage />
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import { useAbility } from '@casl/vue';
const BookingForm = defineAsyncComponent(() => import('../components/BookingForm.vue'));
const TourGuideForm = defineAsyncComponent(() => import('../components/TourGuideForm.vue'));
const EmployeeForm = defineAsyncComponent(() => import('../components/EmployeeForm.vue'));
const HotelContractForm = defineAsyncComponent(() => import('../components/HotelContractForm.vue'));
const RestaurantContractForm = defineAsyncComponent(() => import('../components/RestaurantContractForm.vue'));
const TransportationContractForm = defineAsyncComponent(() => import('../components/TransportationContractForm.vue'));
const ItineraryDetail = defineAsyncComponent(() => import('../components/ItineraryDetail.vue'));
const NotFoundPage = defineAsyncComponent(() => import('./404.vue'));
// import BookingForm from '../components/BookingForm.vue'
// import TourGuideForm from '../components/TourGuideForm.vue';
// import EmployeeForm from '../components/EmployeeForm.vue';
// import HotelContractForm from '../components/HotelContractForm.vue'
// import RestaurantContractForm from '../components/RestaurantContractForm.vue'
// import TransportationContractForm from '../components/TransportationContractForm.vue'
// import ItineraryDetail from '../components/ItineraryDetail.vue'

export default {
    setup(){
        const store = useStore();
        const { can } = useAbility();

        //On created
        store.dispatch('showSideBar', false)        

        return { 
            can,
        }
    },
    components:{
        BookingForm,
        TourGuideForm,
        EmployeeForm,
        HotelContractForm,
        RestaurantContractForm,
        TransportationContractForm,
        ItineraryDetail,
        NotFoundPage,
    }    
}
</script>

<style lang="scss" scoped>
    .form-content {
        display: flex;
        justify-content: center;
        // margin: auto;
        margin-left: 10px;
        padding-bottom: 2rem;
    }

    @media screen and (max-width: 800px) {
        .form-content {
            display: block;
        }
    }
</style>